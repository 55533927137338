import { MenuItemType } from '@idcms/store'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import SubscribeComponent from '../SubscribeComponent'

type Props = {
    footerMenuItems?: MenuItemType[];
    footerSecondMenuItems?: MenuItemType[];
}

const Footer = ({ footerMenuItems, footerSecondMenuItems }: Props) => {
    const { t, i18n } = useTranslation()
    const renderFooterMenu = footerMenuItems?.map((mi) =>
        <Link key={mi.id} passHref href={`/${mi.slug?.slug ?? ''}`}>
            <Typography sx={{ cursor: 'pointer' }} fontSize={'16px'} lineHeight={'17px'} color={'primary.main'}>
                {mi.name}
            </Typography>
        </Link>
    )
    const renderFooterSecondMenu = footerSecondMenuItems?.map((mi) =>
        <Link key={mi.id} passHref href={`/${mi.slug?.slug ?? ''}`}>
            <Typography sx={{ cursor: 'pointer' }} fontSize={'16px'} lineHeight={'17px'} color={'primary.main'}>
                {mi.name}
            </Typography>
        </Link>
    )
    const year = new Date().toLocaleString(i18n.language, { year: 'numeric' })

    return (
        <>
            <SubscribeComponent />
            <Stack sx={{ backgroundColor: '#F1F1F1', width: '100%', position: 'relative', zIndex: 10 }}>
                <Grid container width={'100%'}
                    sx={{
                        backgroundColor: '#F1F1F1', width: '100%',
                        paddingX: { sm: 3, md: 5, lg: 'auto' },
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                    pt={6} pb={4} px={{ lg: 5, md: 5, sm: 3, xs: 3 }}>

                    <Grid item ml={{ lg: 2, md: 2, sm: 1, xs: 1 }} mr={5} mt={5.5} sx={{ transform: 'scale(1.2)' }}>
                        <Image
                            width={78.926}
                            height={106.001}
                            priority
                            src={'/assets/icons/logo-plain.svg'}
                            alt="logo"
                        />

                    </Grid>

                    <Grid item display={'flex'} justifyContent={'flex-end'} flexDirection={'row'} mt={4}>
                        <Stack direction={{ lg: 'row', md: 'row', sm: 'row', xs: 'column' }} spacing={{ lg: 10, md: 10, sm: 10, xs: 6 }}>
                            <Stack spacing={2}>
                                {renderFooterMenu}
                            </Stack>
                            <Stack spacing={2}>
                                {renderFooterSecondMenu}
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid item display={'flex'} flexDirection={'row'} ml={{ lg: 5, md: 0, sm: 0, xs: 0 }} mt={{ lg: 0, md: 0, sm: 2, xs: 4 }}
                        width={{ lg: '30%', md: '50%', sm: '100%', xs: '100%' }}
                        justifyContent={{ lg: 'flex-end', md: 'flex-end', sm: 'flex-start', xs: 'space-between' }} >
                        <Stack direction={"row"} spacing={{ md: 4, xs: 2 }} width={'100%'} justifyContent={'space-between'} mt={4}>
                            <Stack justifyContent={'top'} alignItems={'center'}>
                                <Stack sx={{ transform: { md: 'scale(1.2)', xs: 'scale(1)' }, pb: .8 }}>
                                    <Image
                                        width={58.666}
                                        height={51.209}
                                        priority
                                        src={'/assets/icons/moneyback-icon.svg'}
                                        alt="moneyback"
                                    />
                                </Stack>
                                <Typography maxWidth={'140px'} textAlign={'center'} variant='body2' lineHeight={'17px'} pt={2} color={'primary.main'}>
                                    {t('guarantees.moneyBackGuarantee')}
                                </Typography>
                            </Stack>
                            <Stack justifyContent={'top'} alignItems={'center'}>
                                <Stack sx={{ transform: { md: 'scale(1.2)', xs: 'scale(1)' } }}>
                                    <Image
                                        width={42.773}
                                        height={58.828}
                                        priority
                                        src={'/assets/icons/guarantee-icon.svg'}
                                        alt="guarantee"
                                    />
                                </Stack>
                                <Typography maxWidth={'140px'} textAlign={'center'} variant='body2' lineHeight={'17px'} pt={2} color={'primary.main'}>
                                    {t('guarantees.guaranteesForGoods')}
                                </Typography>
                            </Stack>
                            <Stack justifyContent={'top'} alignItems={'center'}>
                                <Stack sx={{ transform: { md: 'scale(1.2)', xs: 'scale(1)' } }}>
                                    <Image
                                        width={50.3}
                                        height={54.604}
                                        priority
                                        src={'/assets/icons/safety-icon.svg'}
                                        alt="safety"
                                    />
                                </Stack>
                                <Typography maxWidth={'140px'} textAlign={'center'} variant='body2' lineHeight={'17px'} pt={2} color={'primary.main'}>
                                    {t('guarantees.dataSecurity')}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>

            <Stack
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: 1,
                    backgroundColor: '#fff',
                    width: '100%',
                    zIndex: 10,
                    position: 'relative'
                }}>
                <Typography sx={{ fontSize: '14px', color: 'primary.main' }}>

                    &copy; {year} {t('footer.copyright')}
                </Typography>
            </Stack>
        </>
    )
}

export default Footer