import { MenuItemType, useAppSelector } from "@idcms/store";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Stack from "@mui/material/Stack";
import { useTranslation } from "next-i18next";
import Image from 'next/image';
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import styles from "../../styles/Header.module.scss";
import DynamicConfirmedEmailForm from "../shared/auth/DynamicConfirmedEmailForm";
import DynamicResetPasswordFormComponent from "../shared/auth/DynamicResetPasswordFormComponent";
import { selectCart } from "../store/cartSlice";
import { selectWishlist } from "../store/wishlistSlice";
import { getTheme } from "./Theme";
import DynamicAccountDropdown from "./menus/DynamicAccountDropdown";
import DynamicCartDropdown from "./menus/cart-dropdown/DynamicCartDropdown";
import DynamicLikedDropdown from "./menus/favorites-dropdown/DynamicLikedDropdown";
import DynamicDropdownMenuMobile from "./menus/mobile-menu/DynamicDropdownMenuMobile";
import DynamicSidemenu from "./menus/product-menu/DynamicSidemenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";

type Props = {
  informationMenuItems?: MenuItemType[];
  sideMenuItems?: MenuItemType[];
  servicesMenuItems?: MenuItemType[];
}


export const HeaderMob = ({ informationMenuItems, sideMenuItems, servicesMenuItems }: Props) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const toggleMobileMenu = () => {
    !openMobileMenu ? setOpenMobileMenu(true) : setOpenMobileMenu(false)
  }

  const [openAccountDropdown, setOpenAccountDropdown] = useState(false)
  const [openLikedDropdown, setOpenLikedDropdown] = useState(false)
  const [openCartDropdown, setOpenCartDropdown] = useState(false)

  const toggleAccountDropdown = () => {
    setOpenAccountDropdown(!openAccountDropdown)
  }
  const toggleLikedDropdown = () => {
    setOpenLikedDropdown(!openLikedDropdown)
  }
  const toggleCartDropdown = () => {
    setOpenCartDropdown(!openCartDropdown)
  }
  return (
    <>
      <div className={styles.mobileMenu}>
        <Link href={'/'} passHref aria-label="idemus.lt">
          <div className={styles.logoSmall} style={{ cursor: 'pointer' }}>
            <Image
              width={45.688}
              height={49.435}
              priority
              src={'/assets/icons/logo-small.svg'}
              alt="logo"
            />
          </div>
        </Link>
        <div className={styles.userNavMobile}>
          <div className={styles.userLinkMob} onClick={toggleAccountDropdown}>
            <span className={styles.specialMob} style={{ cursor: 'pointer' }}>
              <Image
                width={27}
                height={25}
                priority
                src={'/assets/icons/account-icon.svg'}
                alt="account"
              />
            </span>
          </div>
          {openAccountDropdown && <DynamicAccountDropdown toggleAccountDropdown={toggleAccountDropdown} />}

          <div className={styles.userLinkMob} onClick={toggleLikedDropdown}>
            <span style={{ cursor: 'pointer' }}>
              <Image
                width={28}
                height={25.2}
                priority
                src={'/assets/icons/favorite-icon.svg'}
                alt="favorite"
              />
            </span>
          </div>
          {openLikedDropdown && <DynamicLikedDropdown toggleLikedDropdown={toggleLikedDropdown} />}

          <div className={styles.userLinkMob} onClick={toggleCartDropdown}>
            <span style={{ cursor: 'pointer' }}>
              <Image
                width={28.563}
                height={25.74}
                priority
                src={'/assets/icons/cart-icon.svg'}
                alt="cart"
              />
            </span>
          </div>
          {openCartDropdown && <DynamicCartDropdown toggleCartDropdown={toggleCartDropdown} />}

          <div className={styles.sideMenuIcon} onClick={toggleMobileMenu}>
            <>
              {openMobileMenu &&
                <CloseRoundedIcon fontSize="large" sx={{ color: "#fff" }} />
              }
              {!openMobileMenu &&
                <MenuRoundedIcon fontSize="large" sx={{ color: "#fff" }} />
              }
            </>
          </div>
        </div>
      </div>
      {openMobileMenu &&
        <div className={styles.mobileHeader} style={{ position: 'relative' }}>
          <DynamicDropdownMenuMobile toggleMobileMenu={toggleMobileMenu} servicesMenuItems={servicesMenuItems} sideMenuItems={sideMenuItems} informationMenuItems={informationMenuItems} />
        </div>
      }
    </>
  )
}



export const Header = ({ sideMenuItems, informationMenuItems, servicesMenuItems }: Props) => {
  const [openSideMenu, setOpenSideMenu] = useState(false)
  const [openAccountDropdown, setOpenAccountDropdown] = useState(false)
  const [openLikedDropdown, setOpenLikedDropdown] = useState(false)
  const [openCartDropdown, setOpenCartDropdown] = useState(false)

  const { t, i18n } = useTranslation()

  const changeLocale = (locale: string) => {
    locale = locale.toLocaleLowerCase()
    i18n.changeLanguage(locale)
    router.push({
      pathname: router.pathname,
      query: router.query
    }, {
      pathname: router.pathname,
      query: router.query
    }, { locale });
  }


  const toggleSideMenu = () => {
    !openSideMenu ? setOpenSideMenu(true) : setOpenSideMenu(false)
  }
  const toggleAccountDropdown = () => {
    !openAccountDropdown ? setOpenAccountDropdown(true) : setOpenAccountDropdown(false)
  }
  const toggleLikedDropdown = () => {
    !openLikedDropdown ? setOpenLikedDropdown(true) : setOpenLikedDropdown(false)
  }
  const toggleCartDropdown = () => {
    !openCartDropdown ? setOpenCartDropdown(true) : setOpenCartDropdown(false)
  }

  const [toggleResetPassword, setToggleResetPassword] = useState(false);
  const [toggleConfirmedEmail, setToggleConfirmedEmail] = useState(false);
  const router = useRouter();

  const { resetPassword } = router.query
  const { confirmedEmail } = router.query
  const { passwordResetCompleted } = router.query

  useEffect(() => {
    if (passwordResetCompleted) {
      setToggleResetPassword(false)
    }
    if (resetPassword) { setToggleResetPassword(true) }
    if (confirmedEmail) { setToggleConfirmedEmail(true) }
    //eslint-disable-next-line
  }, [router.query])


  const [searchQuery, setSearchQuery] = useState<string>();

  useEffect(() => {
    router.query.search ? router.query.search : setSearchQuery("");

    router.query.search instanceof Array
      ? router.query.search[0]
      : router.query.search;

    router.query.search ? router.query.search : setSearchQuery("");

    Array.isArray(router.query.search)
      ? setSearchQuery(router.query.search[0])
      : router.query.search;

    setSearchQuery(router.query.search?.toString());
  }, [router.query.search]);

  const onSearchQueryChanged = (e: any) => {
    e.preventDefault();
    if (searchQuery !== undefined && searchQuery !== '')
      router.push({
        pathname: "/ortopedijos-gaminiai",
        query: { ...router.query, search: searchQuery },
      });
  }
  const [changeCartColor, setChangeCartColor] = useState(false)
  const [changeWishlistColor, setChangeWishlistColor] = useState(false)
  const cart = useAppSelector(selectCart);
  const wishlist = useAppSelector(selectWishlist);

  const theme = getTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <>
      {!matches &&
        <HeaderMob informationMenuItems={informationMenuItems}
          servicesMenuItems={servicesMenuItems}
          sideMenuItems={sideMenuItems} />}
      {matches &&
        <div className={styles.headerComponent}>
          <div className={styles.header}>

            <div className={styles.topNav}>
              <div className={styles.topNavContacts}>
                <Stack direction={'row'} spacing={.5} className={styles.email}>
                  <Image
                    width={17.533}
                    height={12.175}
                    priority
                    src={'/assets/icons/email-icon.svg'}
                    alt="email"
                  />
                  <Link href="mailto:info@idemus.lt" passHref>info@idemus.lt</Link>
                </Stack>

                <div className={styles.phone}>
                  <Image
                    width={17.533}
                    height={17.465}
                    priority
                    src={'/assets/icons/phone-icon.svg'}
                    alt="phone"
                  />
                  <Link passHref href="tel:+37061200888">+370 612 00888</Link>
                </div>
              </div>

              <div className={styles.topNavLanguages}>
                <span onClick={() => changeLocale('LT')} style={{ cursor: 'pointer', fontWeight: i18n.language === 'lt' ? 600 : 300 }}>
                  LT
                </span>
                <span onClick={() => changeLocale('EN')} style={{ cursor: 'pointer', fontWeight: i18n.language === 'en' ? 600 : 300 }}>
                  EN
                </span>
              </div>
            </div>

            <div className={styles.navbar} style={{ zIndex: -2 }}>
              <Link href={'/'} passHref aria-label="idemus.lt">
                <div className={styles.logo} style={{ cursor: 'pointer' }}>
                  <Image
                    width={78.926}
                    height={106.001}
                    priority
                    src={'/assets/icons/logo.svg'}
                    alt="logo"
                  />
                </div>
              </Link>

              <div className={styles.mainNav}>
                <div className={styles.nav}>
                  <Stack style={{ cursor: 'pointer' }} onClick={toggleSideMenu}>
                    <Typography fontSize={18}>
                      <span className={styles.productsLinkButton}>
                        {t('header.products').toUpperCase()}
                      </span>
                    </Typography>
                  </Stack>


                  <Link href="/paslaugos" passHref className="removeLinkUnderline">
                    <Stack direction={'row'} style={{ cursor: 'pointer' }} height={'100%'} alignItems={'center'}>
                      <Typography fontSize={18} className={styles.servicesLinkButton}>
                        {t('header.services').toUpperCase()}
                      </Typography>
                    </Stack>
                  </Link>

                  <Link href="/informacija/apie-mus" passHref className="removeLinkUnderline">
                    <Stack direction={'row'} style={{ cursor: 'pointer' }} height={'100%'} alignItems={'center'}>
                      <Typography fontSize={18} className={styles.informationLinkButton}>
                        {t('header.information').toUpperCase()}
                      </Typography>
                    </Stack>
                  </Link>
                </div>
                <div className={styles.userNav}>
                  <div className={styles.userLink} style={{ cursor: 'pointer' }} onClick={toggleAccountDropdown}>
                    <span className={styles.special}>
                      <Image
                        width={27}
                        height={25}
                        priority
                        src={'/assets/icons/account-icon.svg'}
                        alt="account"
                      />
                    </span>
                    <span className={styles.toHideLinks}>
                      {t('header.myAccount.title').toUpperCase()}
                    </span>
                  </div>
                  <div className={styles.userLink} style={{ cursor: 'pointer' }} onClick={toggleLikedDropdown}
                    onMouseEnter={() => setChangeWishlistColor(true)} onMouseLeave={() => setChangeWishlistColor(false)}>
                    <span className={styles.favIcon} style={{ position: 'relative' }}>
                      {wishlist?.products?.length > 0 &&
                        <Stack sx={{
                          top: '-4px',
                          position: 'absolute',
                          marginLeft: '22px',
                          backgroundColor: changeWishlistColor ? theme.palette.primary.main : theme.palette.secondary.main, width: 16, height: 16, borderRadius: '50%',
                          color: '#fff', alignItems: 'center'
                        }}>
                          <Typography fontSize={'10px'}>
                            {wishlist?.products?.length}
                          </Typography>
                        </Stack>
                      }
                      <Image
                        width={28}
                        height={25.2}
                        priority
                        src={'/assets/icons/favorite-icon.svg'}
                        alt="favorite"
                      />
                    </span>

                    <span className={styles.toHideLinks}>
                      {t('header.likedProducts.title').toUpperCase()}
                    </span>
                  </div>

                  <div className={styles.userLink} style={{ cursor: 'pointer' }} onMouseEnter={() => setChangeCartColor(true)} onMouseLeave={() => setChangeCartColor(false)} onClick={toggleCartDropdown}>
                    <span className={styles.red} style={{ position: 'relative' }}>
                      {cart?.items?.length > 0 &&
                        <Stack sx={{
                          top: '-4px',
                          position: 'absolute',
                          marginLeft: '22px',
                          backgroundColor: changeCartColor ? theme.palette.primary.main : theme.palette.secondary.main, width: 16, height: 16, borderRadius: '50%',
                          color: '#fff', alignItems: 'center'
                        }}>
                          <Typography fontSize={'10px'}>
                            {cart?.items?.length}
                          </Typography>
                        </Stack>
                      }
                      <Image
                        width={28.563}
                        height={25.74}
                        priority
                        src={'/assets/icons/cart-icon.svg'}
                        alt="cart"
                      />
                    </span>
                    <span className={styles.toHideLinks}>
                      {t('header.productCart.title').toUpperCase()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {openAccountDropdown && <DynamicAccountDropdown toggleAccountDropdown={toggleAccountDropdown} />}
            {openLikedDropdown && <DynamicLikedDropdown toggleLikedDropdown={toggleLikedDropdown} />}
            {openCartDropdown && <DynamicCartDropdown toggleCartDropdown={toggleCartDropdown} />}
            <form onSubmit={onSearchQueryChanged}>
              <Stack direction={'row'} spacing={2} position={'relative'} top={'-30px'} zIndex={-2} justifyContent={'flex-end'}>
                <OutlinedInput fullWidth sx={{ height: '36px', marginLeft: '22%', maxWidth: 'calc(100% - 430px)' }} placeholder={t('header.searchPlaceholder')}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery || ""} />
                <Button type="submit" variant="contained" color={'secondary'} sx={{ color: '#fff', minWidth: '280px', height: '36px', fontWeight: 400 }}>
                  {t('header.search').toUpperCase()}
                </Button>
              </Stack>
            </form>
          </div>
          <div className={styles.headerSpaceFiller}></div>
        </div>}
      {openSideMenu &&
        <div className={styles.sideMenu}>
          <DynamicSidemenu sideMenuItems={sideMenuItems} toggleSideMenu={toggleSideMenu} openSideMenu={openSideMenu} />
        </div>}

      <DynamicResetPasswordFormComponent toggleResetPassword={toggleResetPassword} setToggleResetPassword={setToggleResetPassword} />
      <DynamicConfirmedEmailForm setToggleConfirmedEmail={setToggleConfirmedEmail} toggleConfirmedEmail={toggleConfirmedEmail} toggleAccountDropdown={toggleAccountDropdown} />
    </>
  )
}
