import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { getSubscribeSchema, REQUEST_SUBSCRIPTION_MUTATION, SubscribeVariables, SubscriptionResult } from '@idcms/store';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import ErrorBox from './shared/errors/ErrorBox';
import Message from './shared/messages/Message';


const SubscribeComponent = () => {
    const [subscribe, { data, error }] = useMutation<SubscriptionResult, SubscribeVariables>(REQUEST_SUBSCRIPTION_MUTATION(), {
        errorPolicy: "all",
        onError: () => { },
    })

    const { t } = useTranslation("common")
    const { register, handleSubmit, formState: { errors } } = useForm<SubscribeVariables>({
        resolver: yupResolver(getSubscribeSchema({ t } as any)),
    })

    const submit: SubmitHandler<SubscribeVariables> = async (args: SubscribeVariables) => {
        await subscribe({
            errorPolicy: "all",
            variables: args
        })
    }
    return (
        <Grid container width={100} sx={{ backgroundColor: 'primary.main', width: '100%', position: 'relative', zIndex: 10 }}
            px={{ lg: 6, md: 6, sm: 3, xs: 3 }} pt={6} pb={6}>
            <Stack spacing={{ xs: 3, md: 6, lg: 6 }} direction={{ xs: 'column', md: 'row', lg: 'row' }}
                alignItems={'center'} width={'100%'} justifyContent={'center'}>
                <Typography variant='h5' component={'h3'} fontWeight={'light'} color={'#fff'} pb={1}>
                    {t('subscribe.getNewsletters')}
                </Typography>
                <Stack direction={'column'} width={{ lg: '50%', md: '50%', sm: '100%', xs: '100%' }}>
                    <form
                        onSubmit={handleSubmit(submit)}
                    >
                        <Stack direction={{ lg: 'row', md: 'row', sm: 'row', xs: 'column' }} width={'100%'} spacing={{ lg: 0, md: 0, sm: 0, xs: 1.5 }}>
                            <OutlinedInput sx={{
                                height: '40px', borderEndEndRadius: { lg: 0, md: 0, sm: 0, xs: 4 },
                                borderStartEndRadius: { lg: 0, md: 0, sm: 0, xs: 4 }, border: 'none', minWidth: 240
                            }}  {...register("email")}
                                placeholder={t('subscribe.email')} fullWidth />
                            <Button variant={'contained'} color={'secondary'} type={'submit'}
                                sx={{
                                    borderEndStartRadius: { lg: 0, md: 0, sm: 0, xs: 4 },
                                    borderStartStartRadius: { lg: 0, md: 0, sm: 0, xs: 4 }, px: 4, height: 40, minWidth: { lg: 250, xs: '180px' }
                                }}>
                                {t('subscribe.subscribe')}
                            </Button>
                        </Stack>
                        <ErrorBox errors={errors.email} />
                        {error && <ErrorBox errors={error} />}
                        {data && !error && <Message message={t('subscribe.subscribeConfirmation')} />}
                    </form>
                </Stack>
            </Stack>
        </Grid>
    )
}

export default SubscribeComponent