import { CategoryType, PageType, PostType, ProductType } from '@idcms/store';
import React from 'react'
import Head from 'next/head';

type Props = {
    page?: PageType | null;
    post?: PostType | null;
    product?: ProductType | null;
    category?: CategoryType | null;
    title?: string;

}
const PageHead = ({ page, product, category, post, title }: Props) => {
    return (
        <Head>
            {post && <>
                <title>{title ?? post?.translation?.title}</title>
                {post?.translation?.seoDescription &&
                    <meta
                        name="description"
                        content={post?.translation?.seoDescription}
                        key="desc"
                    />
                }
            </>}
            {page && <>
                <title>{title ?? page?.translation?.title}</title>
                {page?.translation?.seoDescription &&
                    <meta
                        name="description"
                        content={page?.translation?.seoDescription}
                        key="desc"
                    />
                }
            </>}
            {category &&
                <>
                    <title>{title ?? category?.translation?.title}</title>
                    {category?.translation?.seoDescription &&
                        <meta
                            name="description"
                            content={category?.translation?.seoDescription}
                            key="desc"
                        />
                    }
                </>}
            {product &&
                <>
                    <title>{title ?? product?.translation?.title}</title>
                    <meta
                        name="description"
                        content={product?.translation?.seoDescription ?? product?.translation?.title}
                        key="desc"
                    />
                    <meta
                        name="keywords"
                        content={product?.translation?.seoKeywords ?? product?.translation?.title}
                        key="desc"
                    />
                </>}
        </Head>
    )
}

export default PageHead