import { MenuItemType } from '@idcms/store';
import Stack from '@mui/material/Stack';
import React from 'react';
import styles from '../../styles/Layout.module.scss';
import Cookies from './Cookies';
import Footer from './Footer';
import { Header } from './Header';

type Props = {
  children: React.ReactNode;
  bgColor?: string;
  sideMenuItems?: MenuItemType[];
  footerMenuItems?: MenuItemType[];
  footerSecondMenuItems?: MenuItemType[];
  informationMenuItems?: MenuItemType[];
  servicesMenuItems?: MenuItemType[];
}

const Layout = ({ children, bgColor, footerMenuItems, footerSecondMenuItems, servicesMenuItems, sideMenuItems, informationMenuItems }: Props) => {

  return (
    <>
      <Stack sx={{ minHeight: '73vh', backgroundColor: bgColor ? bgColor : '#fff' }}>
        <Header servicesMenuItems={servicesMenuItems} sideMenuItems={sideMenuItems} informationMenuItems={informationMenuItems} />
        <div className={styles.layoutBody}>{children}</div>
      </Stack>
      <Cookies />
      <Footer footerMenuItems={footerMenuItems} footerSecondMenuItems={footerSecondMenuItems} />
    </>
  )
}

export default Layout