import React, { useEffect, useState } from 'react'
import Link from 'next/link';
import Stack from '@mui/material/Stack';
import { useCookies } from 'react-cookie';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'next-i18next';
import { getTheme } from './Theme';

function Cookies() {
    const [cookies, setCookie] = useCookies(['gdpr-google-analytics'])
    const [showBar, setShowBar] = useState(false)
    const [show, setShow] = useState(true)
    const [mounted, setMounted] = useState(false)

    const isCookie = (cookies['gdpr-google-analytics'])
    const { t } = useTranslation()
    useEffect(() => {
        setMounted(true)
        setTimeout(function () {
            setTimeout(function () {
                if (mounted && !cookies['gdpr-google-analytics'])
                    setShowBar(true);
            }, 500);
        }, 2000);

        return () => setMounted(false);
    }, [cookies, showBar, mounted]);

    if (!mounted) {
        return null
    }
    const acceptPrivacyPolicy = () => {
        // initializeAndTrack(location);
        setCookie('gdpr-google-analytics', true, { path: '/', maxAge: 31536000 });
    }
    const rejectPrivacyPolicy = () => {
        // initializeAndTrack(location);
        setShow(false)
        setCookie('gdpr-google-analytics', false, { path: '/', maxAge: 31536000 });
    }
    const theme = getTheme()
    return (
        <>
            {show && <>
                {isCookie && <></>}
                {!isCookie &&
                    <Stack direction={{ lg: 'row', md: 'row', sm: 'column', xs: 'column' }} justifyContent={'space-between'}
                        alignItems={{ lg: 'center', md: 'center', sm: 'center', xs: 'flex-start' }} px={{ lg: 6, md: 6, sm: 4, xs: 4 }} py={3}
                        position={'fixed'} bottom={0} zIndex={999}
                        sx={{ backgroundColor: theme.palette.primary.main + 'fa', width: '100%' }}>
                        <Stack width={'100%'} mb={1}>
                            <Typography color={'#fff'} fontSize={'15px'} fontWeight={600} pb={1}>
                                {t('cookies.title')}
                            </Typography>
                            <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'top'} pr={{ lg: 4, md: 4, sm: 0, xs: 0 }}>
                                <Typography color={'#fff'} fontSize={'12px'} fontWeight={400} lineHeight={'15px'}>
                                    {t('cookies.textPartOne')} {' '}
                                    <Link href="/privatumo-politika" passHref>
                                        <span style={{ textDecoration: 'underline' }}>{t('cookies.privacyPolicy')}</span>
                                    </Link>{t('cookies.textPartTwo')}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} width={{ md: '40%', sm: '50%', xs: '100%' }} spacing={2} pt={1} alignSelf={{ md: 'flex-end', sm: 'flex-end', xs: 'space-between' }}>
                            <Button fullWidth onClick={acceptPrivacyPolicy} variant={'contained'} color={'info'} >
                                <Typography color={'#192357'} fontSize={'14px'} fontWeight={400} px={3} py={1}>{t('cookies.actions.accept')}</Typography>
                            </Button>
                            <Button fullWidth onClick={rejectPrivacyPolicy} variant={'outlined'} color={'info'} >
                                <Typography color={'#fff'} fontSize={'14px'} fontWeight={400} px={3} py={1}>{t('cookies.actions.reject')}</Typography>
                            </Button>
                        </Stack>
                    </Stack>
                }
            </>}
        </>
    )
}

export default Cookies
